import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "send-driver-sms",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class SendDriverSMS extends BaseComponent {

  @Input() token;
  @Input() driver;
  @Input() jobId;
  @Input() jobCode;
  @Input() pickDt;
  public driverUrl;
  
  ngOnInit(): void {
    super.ngOnInit();
    this.onDriverLinkTypeChanged();
  }

  onDriverLinkTypeChanged() {
    this.driverUrl = this.createDriverLink();
  }

  private createDriverLink() {
    if (!this.token) {
      return '';
    }
    const nativeAppLink = 'https://native-app.wearewarp.com/driver';    // TODO: server sẽ trả về URL trong master data
    return `${nativeAppLink}/${this.token}`;
  }

  private _smsConttent;
  public get smsContent(){
    if(this._smsConttent) return this._smsConttent;
    let pickTimeTxt = 'today';
    if (this.pickDt?.time && this.pickDt?.timezone) {
      pickTimeTxt = DateUtil.displayLocalTime(this.pickDt.time, { timezone: this.pickDt.timezone, format: 'MMM D' });
      pickTimeTxt = `on ${pickTimeTxt}`;
    }
    //default content
    return `${this.getFullName(this.driver)}, this is Warp, you have a route ${pickTimeTxt}. Please click the link to go to driver app then START route [${this.jobCode}].`
  }
  public set smsContent(content){
    this._smsConttent = content;
  }
  public isSendingSMS = false;

  copyDriverUrl(elm: HTMLElement) {
    Utils.copyTextToClipboard(this.driverUrl, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('URL has already been copied to the clipboard');
        Utils.selectAll(elm);
      }
    })
  }

  onBtnSendSMS() {
    this.isSendingSMS = true;
    let text = this.smsContent;
    if (text) {
      text += '\n\n';
    }
    text += this.driverUrl;
    let params = {
      driverId: this.driver._id,
      phone: this.driver.phone,
      text: text,
    };
    let url = `${Const.APIURI_JOBS}/${this.jobId}/driver_sms`;
    this.api.POST(url, params).subscribe(
      resp => {
        Log.d('send driver sms done ', resp);
        this.isSendingSMS = false;
        this.showSuccess(`An SMS has been sent to ${this.getDriverName(this.driver)}.`);
      }, err => {
        this.showErr(err);
        this.isSendingSMS = false;
      }
    );
  }
  
}
