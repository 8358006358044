<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div class="flex">
      <button *ngIf="!isCreateNew && tabIndex == 0" nz-button nzDanger (click)="onBtnUnassignCarrier()">Unassign Carrier</button>
      <button *ngIf="isJobStarted && tabIndex == 1" nz-button (click)="onBtnChangeDriverWhileJobStarted()" class="right10">Change Driver</button>
      <button *ngIf="!isCreateNew && tabIndex == 1" nz-button nzDanger (click)="onBtnUnassignDriver()">Unassign Driver</button>
      <button *ngIf="isJobStarted && tabIndex == 2" nz-button (click)="onBtnChangeSecondaryDriverWhileJobStarted()" class="right10">Change Driver</button>
      <button *ngIf="!isCreateNew && tabIndex == 2" nz-button nzDanger (click)="onBtnUnassignSecondaryDriver()">Unassign Driver</button>
      <div class="left10"><span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span></div>
    </div>
  </div>
</ng-template>
<div>
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="!isLoading" class="carrier-driver-tabs">
    <form class="form-detail" [formGroup]="formInput" nz-form>
      <nz-tabset (nzSelectedIndexChange)="onTabChange($event)" [nzSelectedIndex]="tabIndex">
        <nz-tab nzTitle="Carrier">
          <div [formGroup]="formInput.get('assignedCarrier')">
            <div nz-row [nzGutter]="{ md: 16 }">
              <div nz-col nzSm="16">
                <ng-container *ngFor="let key of ['carrierId']">
                  <div class="form-label-v2 bottom5">{{getLabel(fullKey('assignedCarrier',key))}}<span *ngIf="isRequired(fullKey('assignedCarrier',key))" class="label-mark-required"></span></div>
                  <app-form-carrier [isDisabled]="shouldDisableFormCarrier" [formControlName]="key" (ngModelChange)="onCarrierChange($event)"></app-form-carrier>
                </ng-container>
              </div>
              <div nz-col nzSm="8">
                <div class="bottom10">Equipment</div>
                <div class="medium">{{equipmentInfo || 'N/A'}}</div>
              </div>
            </div>
            <div class="top5 flex">
              <a (click)="copyCarrierUrl()" nz-tooltip
                nzTooltipTitle="Copy link for carrier">
                <img src="/assets/img/dispatch-icons/link.svg" style="height: 24px; width: 24px;"/>
              </a>
              <a *ngIf="carrierWebUrl" [href]="carrierWebUrl" target="_blank" class="copy-carrier-link-text">
                Route link for Carrier
              </a>
            </div>
          </div>
        </nz-tab>
        <nz-tab nzTitle="{{isShowSecondaryDriverTab ? 'Primary Driver' : 'Driver'}}" [nzDisabled]="shouldDisableDriverTab">
          <ng-container *ngIf="!shouldDisableDriverTab">
            <div [formGroup]="formInput.get('assignedDriver')">
              <ng-container *ngFor="let key of ['driverId']">
                <div class="form-label-v2 bottom5">{{getLabel(fullKey('assignedDriver',key))}}<span *ngIf="isRequired(fullKey('assignedDriver',key))" class="label-mark-required"></span></div>
                <app-form-driver [formControlName]="key" [carrierId]="carrierId" [isDisabled]="isJobStarted"></app-form-driver>
              </ng-container>
              <div *ngIf="!isJobStarted">
                <a (click)="onBtnCreateNewDriver()" class="btn-create-driver">
                  <i nz-icon nzType="plus" style="margin-right: 5px;"></i>Create New Driver
                </a>
              </div>
            </div>
            <div class="separator h top10 bottom10"></div>
            <ng-container *ngFor="let key of ['trailerNumber']">
              <div class="form-label-v2 bottom10">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="key"
                    [type]="getInputType(key)"
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)" />
                </nz-form-control>
              </nz-form-item>
            </ng-container>
            <ng-container *ngIf="driverToken">
              <send-driver-sms [token]="driverToken" [jobCode]="jobCode" [jobId]="jobId" [driver]="driverObj" [pickDt]="pickDt"></send-driver-sms>
            </ng-container>
          </ng-container>
        </nz-tab>
        <ng-container *ngIf="isShowSecondaryDriverTab">
          <nz-tab nzTitle="Secondary Driver" [nzDisabled]="shouldDisableDriverTab">
            <ng-container *ngIf="!shouldDisableDriverTab">
              <ng-container *ngFor="let groupKey of [ 'assignedSecondaryDrivers']; let i = index" [formArrayName]="groupKey">
                <form *ngFor="let item of getArrayControls(groupKey); let i = index" nz-form [formGroupName]="i">
                  <ng-container *ngFor="let key of ['driverId']">
                    <div class="form-label-v2 bottom5">{{getLabel(fullKey('assignedSecondaryDrivers',key))}}<span *ngIf="isRequired(fullKey('assignedSecondaryDrivers',key))" class="label-mark-required"></span></div>
                    <app-form-driver [formControlName]="key" [carrierId]="carrierId" [isDisabled]="isJobStarted"></app-form-driver>
                  </ng-container>
                </form>
              </ng-container>
              <!-- <div *ngIf="!isJobStarted">
                <a (click)="onBtnCreateNewDriver()" class="btn-create-driver">
                  <i nz-icon nzType="plus" style="margin-right: 5px;"></i>Create New Driver
                </a>
              </div> -->
            </ng-container>
          </nz-tab>
        </ng-container>
      </nz-tabset>
    </form>
  </div>
</div>
<ng-template [nzModalFooter]>
  <ng-container *ngIf="tabIndex > 0 || this.isAssignCarrierExternalRoute">
    <div *ngIf="!isLoading" class="form-footer-carrier-driver">
      <div form-footer [onProgress]="onProgress" 
      [nzIconCancel]="null" [nzIconOK]="null"
      [canClickOK]="needUpdate && !onProgress && !isLoading"
      [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
      (onOK)="onBtnAssignCarrierDriver()" (onCancel)="closeDialog()"></div>
    </div>
  </ng-container>
</ng-template>
