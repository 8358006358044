<div class="display-address">
  <div class="text-address">
    {{this.displayInfo.type}} at 
    <span *ngIf="this.displayInfo.locationName">
      <a *ngIf="deliveryInfo.warehouseId" target="_blank" [routerLink]="getRouterLinkLocation()" [queryParams]="getRouterLinkLocationQueryParams()">{{this.displayInfo.locationName}}</a>
      <span *ngIf="!deliveryInfo.warehouseId">{{this.displayInfo.locationName}}</span>
      -
    </span>
    {{this.displayInfo.addressText}}
  </div>
  <div class="info-icon">
    <span nz-icon nzType="info-circle" nzTheme="outline" nz-popover nzPopoverTitle="Location Detail"
      [nzPopoverContent]="LocationInfo"></span>
  </div>
  <ng-template #LocationInfo>
    <nz-table class="table-info" nzBordered nzTemplateMode nzSize="small">
      <tbody>
        <tr>
          <td><b>Access Code</b></td>
          <td> {{displayInfo.accessCode || 'N/A'}}</td>
        </tr>
        <tr>
          <td><b>Note</b></td>
          <td> {{displayInfo.note || 'N/A'}}</td>
        </tr>
        <tr>
          <td><b>Instructions</b></td>
          <td> {{displayInfo.instructions || 'N/A'}}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
</div>